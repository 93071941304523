import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/seguranca/auth.service';
import { markFormGroupTouched } from 'src/app/shared/helper/formulario-util';
import {
  anoMesValidator,
  cpfCnpjValidator,
} from 'src/app/shared/helper/validators';
import { MensagemService } from 'src/app/shared/services/mensagem.service';
import { RegistroService } from 'src/app/view/novo-cadastro/services/registro.service';
import { environment } from 'src/environments/environment';
import { EncryptRequest, InfoCard } from '../../model/dashboard';
import { PagSeguroService } from '../../service/pag-seguro.service';
import { CreditCardPreviewComponent } from '../credit-card-preview/credit-card-preview.component';
import { SharedModule } from './../../../../shared/shared.module';
import { ErrorCardEncripted } from './../../model/dashboard';

@Component({
  selector: 'app-dashboard-checkout-assinatura',
  standalone: true,
  imports: [SharedModule, CreditCardPreviewComponent],
  templateUrl: './dashboard-checkout-assinatura.component.html',
  styleUrl: './dashboard-checkout-assinatura.component.scss',
})
export class DashboardCheckoutAssinaturaComponent implements OnInit {
  @Input() display = false;
  @Input() idpacote = -1;
  @Output() eventCloser = new EventEmitter();

  bandeira: string = '/assets/images/visa.svg';

  bandeiraMaster: string = '/assets/images/mastercard.png';
  bandeiraVisa: string = '/assets/images/visa.svg';
  bandeiraElo: string = '/assets/images/elo.png';

  frontCard = true;

  patterns: any = {
    visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
    mastercard:
      /^(5[1-5][0-9]{14}|2(2[2-9][0-9]{12}|[3-6][0-9]{13}|7[01][0-9]{12}|720[0-9]{12}))$/,
    amex: /^3[47][0-9]{13}$/,
    discover:
      /^6(011|5[0-9]{2}|4[4-9][0-9]|22[1-9][0-9]{3}|22[0-9]{3}|[0-5][0-9]{4})[0-9]{12}$/,
    elo: /^((4011|4312|4389|4514|4576|5041|6277|6362|6363)|506[6-9]|509[0-9]|650[0-9]|651[6-9]|6550|6551)[0-9]{12}$/,
  };

  bandeiras: any = {
    visa: '/assets/images/visa.svg',
    mastercard: '/assets/images/mastercard.png',
    discover: '/assets/images/discover.png',
    elo: '/assets/images/elo.png',
    amex: '/assets/images/amex.png',
  };

  form = this.createForm();

  loadding = false;

  constructor(
    private builder: FormBuilder,
    private pagSeguroService: PagSeguroService,
    private registerService: RegistroService,
    private authService: AuthService,
    private messageService: MensagemService,
    private router: Router
  ) {}

  async ngOnInit(): Promise<void> {
    const dadosFake = {
      nome: 'Ademir M Rosa',
      numCartao: '4111111111111111',
      validade: '2025/12',
      codigoCVC: '013',
      documento: '54754448200',
      email: 'lauro.pinto@gmail.com',
      fone: '96981623638',
    };

    if (!environment.production) {
      this.form.patchValue(dadosFake);
    }
  }

  limparCampoNome() {
    this.form.value.nome = '';
  }

  limparCampoNum() {
    this.form.value.numCartao = '';
  }

  limparCampoDatas() {
    this.form.value.validade = '';
  }

  limparCampoCVC() {
    this.frontCard = !this.frontCard;
    this.form.value.codigoCVC = '';
  }

  trocaBandeira() {
    let brandType = '';

    for (const cardType in this.patterns) {
      const regex = this.patterns[cardType];

      if (regex.test(this.form.value.numCartao)) {
        brandType = cardType;
        break;
      }
    }

    this.bandeira = this.bandeiras[brandType];
  }

  onSubmit() {
    markFormGroupTouched(this.form);

    if (this.form.invalid) return;

    const infoCard = this.form.value;

    const expMonth = infoCard.validade?.substring(4, 6);
    const expYear = infoCard.validade?.substring(0, 4);

    const encript: Partial<EncryptRequest> = {
      holder: infoCard.nome,
      number: infoCard.numCartao,
      securityCode: infoCard.codigoCVC,
      expMonth,
      expYear,
    };

    const card = this.pagSeguroService.doEncrypt(encript);

    if (card.errors && card.errors.length > 0) {
      const msg = this.traduzirMensagem(card.errors);
      this.messageService.showWarn(msg);
      return;
    }

    const encrypted = card.encryptedCard;

    const infoCardRequest: Partial<InfoCard> = {
      documento: infoCard.documento,
      cartaoEncriptado: encrypted,
      idpacote: this.idpacote,
      idtenant: this.authService.jwtPayload?.idtenant,
      nome: infoCard.nome,
    };

    this.registerService.purchasePlan(infoCardRequest).subscribe({
      next: async () => {
        this.display = false;
        this.messageService.showSuccess('Pagamento realizado com sucesso');
        await this.authService.obterNovoAccessToken().catch((_) => {
          this.authService
            .logout()
            .then(() => this.router.navigate(['/login']));
        });
        this.router.navigate(['/dashboard']);
      },
    });
  }

  onClose() {
    this.display = false;
    this.eventCloser.emit();
  }

  private createForm() {
    return this.builder.group({
      nome: new FormControl<string>('', {
        validators: [Validators.required, Validators.minLength(5)],
        nonNullable: true,
      }),
      numCartao: new FormControl<string>('', {
        validators: [Validators.required],
        nonNullable: true,
      }),
      validade: new FormControl<string>('', {
        validators: [Validators.required, anoMesValidator()],
        nonNullable: true,
      }),
      codigoCVC: new FormControl<string>('', {
        validators: [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(4),
        ],
        nonNullable: true,
      }),
      documento: new FormControl<string>('', {
        validators: [
          Validators.required,
          Validators.minLength(11),
          cpfCnpjValidator(),
        ],
        nonNullable: true,
      }),
    });
  }

  private traduzirMensagem(erros: ErrorCardEncripted[]) {
    const tradutor: { [key in string]: string } = {
      INVALID_NUMBER: 'Número do cartão invalido',
      INVALID_HOLDER: 'Nome do titular inválido',
    };

    const msgs: string[] = [];

    erros.forEach((error) => {
      const msg = tradutor[error.code] || 'Número do cartão invalido';
      msgs.push(msg);
    });

    return msgs.join('\n');
  }
}
