import { AfterContentInit, Component, Input } from '@angular/core';
import { FormControlName, NgModel, UntypedFormControl } from '@angular/forms';
import { getErrorMsg, TypeValidation } from '../../helper/validators';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements AfterContentInit {
  @Input() label?: string;
  @Input() control?: FormControlName | UntypedFormControl | NgModel | any;

  @Input() msg?: string;

  input: any;

  constructor() {}

  ngAfterContentInit(): void {
    const value = this.control;

    if (value instanceof FormControlName) {
      this.input = value as FormControlName;
    }

    if (value instanceof UntypedFormControl) {
      this.input = value as UntypedFormControl;
    }

    if (value instanceof NgModel) {
      this.input = value as NgModel;
    }

    if (this.input === undefined) {
      throw new Error(
        'Esse componente precisa ser usado com uma diretiva ngModel ou formControlName'
      );
    }
  }

  hasError(): boolean {
    return (
      this.input &&
      this.input.invalid &&
      (this.input.dirty || this.input.touched)
    );
  }

  get mensagemErro(): string {
    if (this.msg) {
      return this.msg;
    }

    if (this.control && (this.control.dirty || this.control.touched)) {
      const errors = this.control.errors || {};
      for (const errorName in errors) {
        if (errors.hasOwnProperty(errorName)) {
          const msg = getErrorMsg(
            this.label ?? '',
            errorName as TypeValidation,
            errors[errorName]
          );
          return typeof msg === 'string' ? msg : msg[0];
        }
      }
    }

    return `${this.label} é obrigatório.`;
  }
}
