<p-dialog
  header="Pagamento"
  [(visible)]="display"
  [modal]="true"
  (onHide)="onClose()"
  [closable]="true"
  [contentStyle]="{ overflow: 'visible', padding: 0 }"
>
  <ng-template pTemplate="headless">
    <div class="checkout">

      <p-button class="close-button" icon="pi pi-times" [rounded]="true" [text]="true" severity="danger" (onClick)="onClose()"/>
      <app-credit-card-preview
        [rotate]="!frontCard"
        [titular]="form.value.nome"
        [vencimento]="form.value.validade"
        [numeroCartao]="form.value.numCartao"
        [cvc]="form.value.codigoCVC"
      ></app-credit-card-preview>

      <form
        class="form"
        autocomplete="off"
        novalidate
        [formGroup]="form"
        (submit)="onSubmit()"
      >
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-col-12">
            <app-container-input label="Número do cartão">
              <input
                pInputText
                type="text"
                maxlength="16"
                placeholder="0000 0000 0000 0000"
                formControlName="numCartao"
                (focus)="limparCampoNum()"
                (keyup)="trocaBandeira()"
                (blur)="trocaBandeira()"
                class="form-control"
              />
            </app-container-input>
          </div>

          <div class="p-col-12">
            <app-container-input label="Nome do titular">
              <input
                id="input-name"
                type="text"
                placeholder="Nome como está no cartão"
                pInputText
                formControlName="nome"
                (focus)="limparCampoNome()"
                class="form-control"
              />
            </app-container-input>
          </div>

          <div class="p-col-6">
            <app-container-input label="Validade">
              <p-inputMask
                mask="9999/99"
                [unmask]="true"
                placeholder="aaaa/mm"
                formControlName="validade"
                (focus)="limparCampoNome()"
              ></p-inputMask>
            </app-container-input>
          </div>

          <div class="p-col-6">
            <app-container-input label="CVC">
              <input
                pInputText
                id="input-cvv"
                type="password"
                maxlength="3"
                placeholder="cvc"
                name="codigoCVC"
                (focus)="limparCampoCVC()"
                (blur)="frontCard = !frontCard"
                maxlength="4"
                formControlName="codigoCVC"
                class="form-control"
              />
            </app-container-input>
          </div>

          <div class="p-col-4">
            <app-container-input label="CPF/CNPJ">
              <input
                type="text"
                placeholder="CPF/CNPJ"
                pInputText
                name="documento"
                formControlName="documento"
                (focus)="limparCampoNome()"
                class="form-control"
              />
            </app-container-input>
          </div>

          <div class="p-col-12 p-mt-5">
            <button
              *ngIf="!loadding"
              style="height: 48px; font-weight: bold"
              pButton
              id="input-submit"
              type="submit"
              label="Processar Pagamento"
            ></button>

            <p-progressSpinner
              strokeWidth="8"
              [style]="{ width: '50px', height: '50px' }"
              *ngIf="loadding"
            >
            </p-progressSpinner>
          </div>
        </div>
      </form>
    </div>
  </ng-template>
</p-dialog>
