<div class="container-banner">
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 1196 1080"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_13_51)">
      <path
        d="M604.722 1080H97.2551L283.282 897.157C328.423 851.425 373.574 851.418 418.708 897.17L604.722 1080Z"
        fill="url(#paint0_linear_13_51)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1166.98 918.136C1229.8 980.36 1179.04 1080.05 1109.42 1080L809.584 1079.78L418.165 694.852C373.339 649.204 326.604 645.97 281.765 693.604L144.059 842.952C89.4599 897.038 0.5 859.114 0.5 788.989V79.4452C0.5 34.068 36.9797 -0.234161 82.2309 0.0012039H232.053L1166.98 918.136Z"
        fill="url(#paint1_linear_13_51)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M429.357 0.0495605H741.582C827.357 0.0495605 845.774 86.2579 800.638 130.974L687.549 253.6L429.357 0.0495605Z"
        fill="url(#paint2_linear_13_51)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_13_51"
        x1="-77.5367"
        y1="634.397"
        x2="1557.2"
        y2="362.639"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#003E78" />
        <stop offset="1" stop-color="#0F75B8" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_13_51"
        x1="-77.5367"
        y1="634.397"
        x2="1557.2"
        y2="362.639"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#003E78" />
        <stop offset="1" stop-color="#0F75B8" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_13_51"
        x1="-77.5366"
        y1="634.397"
        x2="1557.2"
        y2="362.639"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#003E78" />
        <stop offset="1" stop-color="#0F75B8" />
      </linearGradient>
      <clipPath id="clip0_13_51">
        <rect
          width="1195"
          height="1080"
          fill="white"
          transform="translate(0.5)"
        />
      </clipPath>
    </defs>
  </svg>

  <div class="info p-d-flex p-jc-center p-ai-center">
    <div class="p-pl-3 p-pr-3">
      <div class="p-d-flex p-jc-center">
        <div class="content-list">
          <h1>ERP fácil de usar e sem burocracia</h1>
          <ul>
            <li class="text-list p-mb-3 p-d-flex p-ai-center">
              Sem fidelidade
            </li>
            <li class="text-list p-mb-3 p-d-flex p-ai-center">
              Suporte gratuito
            </li>
            <li class="text-list p-mb-3 p-d-flex p-ai-center">
              Atualizações périódicas
            </li>
            <li class="text-list p-mb-3 p-d-flex p-ai-center">100% em nuvem</li>
          </ul>
        </div>
      </div>
      <picture class="p-d-flex p-jc-center p-pt-0 p-pr-5 pl-5">
        <img
          fetchpriority="high"
          src="assets/images/imagem-banner-cadastro.png"
          alt="Sistema KronERP - Experimente grátis"
        />
      </picture>
    </div>
  </div>
</div>
