import { Injectable, Renderer2 } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  constructor() {}

  applyTheme(renderer: Renderer2) {
    this.updateFavicon(renderer);
    this.updateLogo(renderer);
  }

  setFavicon(renderer: Renderer2) {
    this.updateFavicon(renderer);
  }

  private updateFavicon(renderer: Renderer2) {
    const favicon = environment.configuracaoThema.favicon;
    let link: HTMLLinkElement =
      document.querySelector("link[rel*='icon']") ||
      renderer.createElement('link');

    link.type = 'image/x-icon';
    link.rel = 'icon';
    link.href = favicon;

    renderer.appendChild(document.head, link);
  }

  private updateLogo(renderer: Renderer2) {
    const root = document.documentElement;

    // Remove qualquer tema anterior
    const existingThemeLink = document.querySelector(
      'link[rel="stylesheet"][data-theme]'
    );

    if (existingThemeLink) {
      existingThemeLink.remove();
    }

    // Adiciona o logotipo
    root.style.setProperty(
      '--logo-url',
      `url('${environment.configuracaoThema.logo}')`
    );

    // Carrega o novo tema
    const link = renderer.createElement('link');

    link.rel = 'stylesheet';
    link.href = `assets/layout/css/${environment.configuracaoThema.theme}.css`;
    link.setAttribute('data-theme', environment.configuracaoThema.theme);
  }
}
