import { MenuItem } from 'primeng/api';
import { Rotas } from 'src/app/rotas';

export const menuCadastro: MenuItem = {
  id: 'menu-cadastro',
  label: 'Cadastros',
  icon: 'pi pi-id-card',
  items: [
    {
      label: 'Administrativos',
      icon: 'pi pi-user-edit',
      items: [
        {
          label: 'Contador',
          icon: 'pi pi-angle-right',
          routerLink: [Rotas.Cadastros.base + Rotas.Cadastros.Contador.update],
        },
        {
          label: 'Empresa',
          icon: 'pi pi-angle-right',
          routerLink: [Rotas.Cadastros.base + Rotas.Cadastros.Empresa.search],
        },
        {
          label: 'Papel',
          icon: 'pi pi-angle-right',
          routerLink: [Rotas.Cadastros.base + Rotas.Cadastros.Papel.search],
        },
        {
          label: 'Usuário',
          icon: 'pi pi-angle-right',
          routerLink: [Rotas.Cadastros.base + Rotas.Cadastros.Usuario.search],
        },
      ],
    },

    {
      label: 'Comerciais',
      icon: 'pi pi-shopping-bag',
      items: [
        {
          label: 'Atividade',
          icon: 'pi pi-angle-right',
          routerLink: [Rotas.Cadastros.base + Rotas.Cadastros.Atividade.search],
        },

        {
          label: 'Cliente',
          icon: 'pi pi-angle-right',
          routerLink: [Rotas.Cadastros.base + Rotas.Cadastros.Cliente.search],
        },

        {
          label: 'Condições de pagamento',
          icon: 'pi pi-angle-right',
          routerLink: [
            Rotas.Cadastros.base + Rotas.Cadastros.CondicaoPagamento.search,
          ],
        },

        {
          label: 'Fornecedor',
          icon: 'pi pi-angle-right',
          routerLink: [
            Rotas.Cadastros.base + Rotas.Cadastros.Fornecedor.search,
          ],
        },

        {
          label: 'Produto promocional',
          icon: 'pi pi-angle-right',
          routerLink: [
            Rotas.Cadastros.base + Rotas.Cadastros.ProdutoPromocional.search,
          ],
        },

        {
          label: 'Região de Venda',
          icon: 'pi pi-angle-right',
          routerLink: [Rotas.Cadastros.base + Rotas.Cadastros.Regiao.search],
        },

        {
          label: 'Situação',
          icon: 'pi pi-angle-right',
          routerLink: [
            Rotas.Cadastros.base +
              Rotas.Cadastros.SituacaoClienteFornecedor.search,
          ],
        },

        {
          label: 'Tabela de preço',
          icon: 'pi pi-angle-right',
          routerLink: [
            Rotas.Cadastros.base + Rotas.Cadastros.TabelaPreco.search,
          ],
        },

        {
          label: 'Tipos de Pagamento',
          icon: 'pi pi-angle-right',
          routerLink: [Rotas.Cadastros.base + Rotas.Cadastros.TipoPag.search],
        },

        {
          label: 'Vendedor',
          icon: 'pi pi-angle-right',
          routerLink: [Rotas.Cadastros.base + Rotas.Cadastros.Vendedor.search],
        },
      ],
    },

    {
      label: 'Financeiro',
      icon: 'pi pi-wallet',
      items: [
        {
          label: 'Agencia bancaria',
          icon: 'pi pi-angle-right',
          routerLink: [
            Rotas.Cadastros.base + Rotas.Cadastros.AgenciaBanco.search,
          ],
        },
        {
          label: 'Conta caixa',
          icon: 'pi pi-angle-right',
          routerLink: [
            Rotas.Cadastros.base + Rotas.Cadastros.ContaCaixa.search,
          ],
        },
        {
          label: 'Plano Natureza Fin.',
          icon: 'pi pi-angle-right',
          routerLink: [
            Rotas.Cadastros.base +
              Rotas.Cadastros.PlanoNaturezaFinanceira.search,
          ],
        },
        {
          label: 'Operadora cartão',
          icon: 'pi pi-angle-right',
          routerLink: [
            Rotas.Cadastros.base + Rotas.Cadastros.OperadoraCartao.search,
          ],
        },
        {
          label: 'Natureza Financeira',
          icon: 'pi pi-angle-right',
          routerLink: [
            Rotas.Cadastros.base + Rotas.Cadastros.NaturezaFinanceira.search,
          ],
        },
        {
          label: 'Tipo de recebimento',
          icon: 'pi pi-angle-right',
          routerLink: [
            Rotas.Cadastros.base + Rotas.Cadastros.TipoRecebimento.search,
          ],
        },
        {
          label: 'Tipo de pagamento',
          icon: 'pi pi-angle-right',
          routerLink: [
            Rotas.Cadastros.base + Rotas.Cadastros.TipoPagamento.search,
          ],
        },
        {
          label: 'Talonário Cheque',
          icon: 'pi pi-angle-right',
          routerLink: [
            Rotas.Cadastros.base + Rotas.Cadastros.TalonarioCheque.search,
          ],
        },
        {
          label: 'Cheque',
          icon: 'pi pi-angle-right',
          routerLink: [Rotas.Cadastros.base + Rotas.Cadastros.Cheque.search],
        },
      ],
    },

    {
      label: 'OS',
      icon: 'pi pi-file-edit',
      items: [
        {
          label: 'Equipamentos',
          icon: 'pi pi-angle-right',
          routerLink: [
            Rotas.Cadastros.base + Rotas.Cadastros.Equipamento.search,
          ],
        },
        {
          label: 'Tecnico',
          icon: 'pi pi-angle-right',
          routerLink: [Rotas.Cadastros.base + Rotas.Cadastros.Tecnico.search],
        },
      ],
    },

    {
      label: 'PDV',
      icon: 'pi pi-home',
      items: [
        {
          label: 'Caixa',
          icon: 'pi pi-angle-right',
          routerLink: [Rotas.Cadastros.base + Rotas.Cadastros.Caixa.search],
        },
        {
          label: 'Operador',
          icon: 'pi pi-angle-right',
          routerLink: [Rotas.Cadastros.base + Rotas.Cadastros.Operador.search],
        },
      ],
    },

    {
      label: 'Pessoal',
      icon: 'pi pi-user',
      items: [
        {
          label: 'Pessoa',
          icon: 'pi pi-angle-right',
          routerLink: [Rotas.Cadastros.base + Rotas.Cadastros.Pessoa.search],
        },
        {
          label: 'Colaborador',
          icon: 'pi pi-users',
          items: [
            {
              label: 'Setor',
              icon: 'pi pi-angle-right',
              routerLink: [Rotas.Cadastros.base + Rotas.Cadastros.Setor.search],
            },
            {
              label: 'Cargo',
              icon: 'pi pi-angle-right',
              routerLink: [Rotas.Cadastros.base + Rotas.Cadastros.Cargo.search],
            },
            {
              label: 'Colaborador',
              icon: 'pi pi-angle-right',
              routerLink: [
                Rotas.Cadastros.base + Rotas.Cadastros.Colaborador.search,
              ],
            },
          ],
        },
      ],
    },

    {
      label: 'Produtos',
      icon: 'pi pi-box',
      items: [
        {
          label: 'Marca',
          icon: 'pi pi-angle-right',
          routerLink: [Rotas.Cadastros.base + Rotas.Cadastros.Marca.search],
        },
        {
          label: 'UM',
          icon: 'pi pi-angle-right',
          routerLink: [
            Rotas.Cadastros.base + Rotas.Cadastros.UnidadeProduto.search,
          ],
        },
        {
          label: 'Grupo',
          icon: 'pi pi-angle-right',
          routerLink: [Rotas.Cadastros.base + Rotas.Cadastros.Grupo.search],
        },
        {
          label: 'SubGrupo',
          icon: 'pi pi-angle-right',
          routerLink: [Rotas.Cadastros.base + Rotas.Cadastros.SubGrupo.search],
        },
        {
          label: 'Produto',
          icon: 'pi pi-angle-right',
          routerLink: [Rotas.Cadastros.base + Rotas.Cadastros.Produto.search],
        },
        {
          label: 'Tabela Nutricional',
          icon: 'pi pi-angle-right',
          routerLink: [
            Rotas.Cadastros.base + Rotas.Cadastros.TabelaNutricional.search,
          ],
        },
        {
          label: 'Cor',
          icon: 'pi pi-angle-right',
          routerLink: [Rotas.Cadastros.base + Rotas.Cadastros.Cor.search],
        },
        {
          label: 'Tamanho',
          icon: 'pi pi-angle-right',
          routerLink: [Rotas.Cadastros.base + Rotas.Cadastros.Tamanho.search],
        },
        {
          label: 'Almoxarifado',
          icon: 'pi pi-angle-right',
          routerLink: [
            Rotas.Cadastros.base + Rotas.Cadastros.Almoxarifado.search,
          ],
        },
      ],
    },

    {
      label: 'Transporte',
      icon: 'pi pi-home',
      items: [
        {
          label: 'Motorista',
          icon: 'pi pi-angle-right',
          routerLink: [Rotas.Cadastros.base + Rotas.Cadastros.Motorista.search],
        },
        {
          label: 'Transportadora',
          icon: 'pi pi-angle-right',
          routerLink: [
            Rotas.Cadastros.base + Rotas.Cadastros.Transportadora.search,
          ],
        },
        {
          label: 'Veiculo',
          icon: 'pi pi-angle-right',
          routerLink: [Rotas.Cadastros.base + Rotas.Cadastros.Veiculo.search],
        },
      ],
    },
  ],
};

const menuNfe: MenuItem = {
  id: 'menu-nfe',
  label: 'NF-e',
  icon: 'pi pi-angle-right',
  routerLink: [Rotas.Comercial.base + Rotas.Comercial.Nfe.search],
};

const menuNfse: MenuItem = {
  id: 'menu-issqn',
  label: 'ISSQN',
  icon: 'pi pi-calculator',
  items: [
    {
      label: 'NFS-e',
      icon: 'pi pi-angle-right',
      routerLink: [Rotas.Comercial.base + Rotas.Comercial.Nfse.search],
    },
    {
      label: 'Serviços',
      icon: 'pi pi-angle-right',
      routerLink: [Rotas.Comercial.base + Rotas.Comercial.Servico.search],
    },
  ],
};

const menuMdfe: MenuItem = {
  id: 'menu-mdfe',
  label: 'MDF-e',
  icon: 'pi pi-angle-right',
  routerLink: [Rotas.Comercial.base + Rotas.Comercial.Mdfe.search],
};

const menuComercial: MenuItem = {
  id: 'menu-comercial',
  label: 'Comercial',
  icon: 'pi pi-shopping-cart',
  items: [
    {
      label: 'Diversos',
      icon: 'pi pi-list',
      items: [
        {
          label: 'Formação Preço',
          icon: 'pi pi-money-bill',
          routerLink: [
            Rotas.Comercial.base +
              Rotas.Comercial.Diversos.FormacaoPreco.search,
          ],
        },
      ],
    },
    {
      label: 'Caixa',
      icon: 'pi pi-wallet',
      items: [
        {
          label: 'Movimento',
          icon: 'pi pi-angle-right',
          routerLink: [
            Rotas.Comercial.base + Rotas.Comercial.Caixa.Movimento.search,
          ],
        },
      ],
    },
    {
      label: 'Devoluções',
      icon: 'pi pi-angle-right',
      routerLink: [Rotas.Comercial.base + Rotas.Comercial.Devolucoes.search],
    },

    {
      label: 'Orçamento',
      icon: 'pi pi-angle-right',
      routerLink: [Rotas.Comercial.base + Rotas.Comercial.Orcamento.search],
    },
    {
      label: 'OS',
      icon: 'pi pi-angle-right',
      routerLink: [Rotas.Comercial.base + Rotas.Comercial.Os.search],
    },
    {
      label: 'PDV',
      icon: 'pi pi-angle-right',
      routerLink: [Rotas.Comercial.base + Rotas.Comercial.Pdv.search],
    },
    {
      label: 'Pedido',
      icon: 'pi pi-angle-right',
      routerLink: [Rotas.Comercial.base + Rotas.Comercial.Pedido.search],
    },
    {
      label: 'Pré-venda',
      icon: 'pi pi-angle-right',
      routerLink: [Rotas.Comercial.base + Rotas.Comercial.PreVenda.search],
    },

    menuNfe,
    menuNfse,
    {
      id: 'menu-transporte',
      label: 'Transporte',
      icon: 'pi pi-car',
      items: [
        {
          label: 'Romaneio',
          icon: 'pi pi-angle-right',
          routerLink: [
            Rotas.Comercial.base + Rotas.Comercial.RomaneioEntrega.search,
          ],
        },
        menuMdfe,
      ],
    },
  ],
};

const menuGerencial: MenuItem = {
  label: 'Gerencial',
  icon: 'pi pi-eraser',
  items: [
    {
      label: 'Auditoria',
      icon: 'pi pi-angle-right',
      routerLink: [Rotas.Gerencial.base + Rotas.Gerencial.Auditoria.search],
    },
    {
      label: 'Restrições',
      icon: 'pi pi-angle-right',
      routerLink: [Rotas.Gerencial.base + Rotas.Gerencial.Retricao.search],
    },
  ],
};

const menuFinaceiro: MenuItem = {
  label: 'Financeiro',
  icon: 'pi pi-wallet',
  items: [
    {
      label: 'Controle de Chq',
      icon: 'pi pi-wallet',
      items: [
        {
          label: 'Custódia',
          icon: 'pi pi-angle-right',
          routerLink: [
            Rotas.Financeiro.base + Rotas.Financeiro.CusodiaCheques.search,
          ],
        },
        {
          label: 'Emissão',
          icon: 'pi pi-angle-right',
          routerLink: [
            Rotas.Financeiro.base + Rotas.Financeiro.EmissaoCheque.search,
          ],
        },
      ],
    },

    {
      label: 'Lançamentos',
      icon: 'pi pi-send',
      items: [
        {
          label: 'Pagar',
          icon: 'pi pi-angle-right',
          routerLink: [
            Rotas.Financeiro.base + Rotas.Financeiro.LancamentoPagar.search,
          ],
        },

        {
          label: 'Receber',
          icon: 'pi pi-dollar',
          items: [
            {
              label: 'Crédiario',
              icon: 'pi pi-angle-right',
              routerLink: [
                Rotas.Financeiro.base +
                  Rotas.Financeiro.LancamentoReceber.search,
              ],
            },
          ],
        },
      ],
    },

    {
      label: 'Movimentos',
      icon: 'pi pi-dollar',
      items: [
        {
          label: 'Crédito Cliente',
          icon: 'pi pi-angle-right',
          routerLink: [
            Rotas.Financeiro.base + Rotas.Financeiro.MovimentosCredito.search,
          ],
        },
      ],
    },

    {
      label: 'Recebimento',
      icon: 'pi pi-angle-right',
      routerLink: [Rotas.Financeiro.base + Rotas.Financeiro.Recebimento.search],
    },
    {
      label: 'Pagamento',
      icon: 'pi pi-angle-right',
      routerLink: [Rotas.Financeiro.base + Rotas.Financeiro.Pagamento.search],
    },
  ],
};

const menuSuprimentos: MenuItem = {
  id: 'menu-suprimentos',
  label: 'Suprimentos',
  icon: 'pi pi-chart-bar',
  items: [
    {
      label: 'Compras',
      icon: 'pi pi-cart-plus',
      items: [
        // {
        //   label: 'Compra Sugerida',
        //   icon: 'pi pi-angle-right',
        //   routerLink: ['/suprimentos/compras/compra-sugerida'],
        // },

        {
          label: 'Cotação',
          icon: 'pi pi-angle-right',
          routerLink: [
            Rotas.Suprimentos.base + Rotas.Suprimentos.Compras.Cotacao.search,
          ],
        },

        {
          label: 'Devolução',
          icon: 'pi pi-angle-right',
          routerLink: [
            Rotas.Suprimentos.base + Rotas.Suprimentos.Compras.Devolucao.search,
          ],
        },

        {
          label: 'Fornecedor x Produto',
          icon: 'pi pi-angle-right',
          routerLink: [
            Rotas.Suprimentos.base +
              Rotas.Suprimentos.Compras.FornecedorProduto.search,
          ],
        },

        // {
        //   label: 'Mapa Comparativo',
        //   icon: 'pi pi-angle-right',
        //   routerLink: ['/suprimentos/compras/mapa-comparativo'],
        // },

        {
          label: 'Pedido',
          icon: 'pi pi-angle-right',
          routerLink: [
            Rotas.Suprimentos.base + Rotas.Suprimentos.Compras.Pedido.search,
          ],
        },

        {
          label: 'Requisição',
          icon: 'pi pi-angle-right',
          routerLink: [
            Rotas.Suprimentos.base +
              Rotas.Suprimentos.Compras.RequisicaoCompra.search,
          ],
        },
      ],
    },

    {
      label: 'Estoque',
      icon: 'pi pi-box',
      items: [
        {
          label: 'Ajustes',
          icon: 'pi pi-angle-right',
          routerLink: [
            Rotas.Suprimentos.base + Rotas.Suprimentos.Estoque.Ajuste.create,
          ],
        },
        {
          label: 'Balanço',
          icon: 'pi pi-angle-right',
          routerLink: [
            Rotas.Suprimentos.base + Rotas.Suprimentos.Estoque.Balanco.search,
          ],
        },
        {
          label: 'Entrada',
          icon: 'pi pi-angle-right',
          routerLink: ['/suprimentos/estoque/entradas'],
        },
        {
          label: 'Movimentação',
          icon: 'pi pi-angle-right',
          routerLink: ['/suprimentos/estoque/movimentacao'],
        },
        {
          label: 'Reajuste',
          icon: 'pi pi-angle-right',
          routerLink: [
            Rotas.Suprimentos.base + Rotas.Suprimentos.Estoque.Reajuste.search,
          ],
        },
        {
          label: 'Requisição Interna',
          icon: 'pi pi-angle-right',
          routerLink: [
            Rotas.Suprimentos.base +
              Rotas.Suprimentos.Estoque.RequisicaoInterna.search,
          ],
        },
        {
          label: 'Transferencia',
          icon: 'pi pi-angle-right',
          routerLink: [
            Rotas.Suprimentos.base +
              Rotas.Suprimentos.Estoque.Transferencias.search,
          ],
        },
      ],
    },
  ],
};

const menuFiscal: MenuItem = {
  id: 'menu-fiscal',
  label: 'Fiscal',
  icon: 'pi pi-dollar',
  items: [
    {
      label: 'Tributação',
      icon: 'pi pi-dollar',
      items: [
        {
          label: 'Grupo Tributário',
          icon: 'pi pi-angle-right',
          routerLink: [Rotas.Fiscal.base + Rotas.Fiscal.GrupoTributario.search],
        },
        {
          label: 'Operação Fiscal',
          icon: 'pi pi-angle-right',
          routerLink: [Rotas.Fiscal.base + Rotas.Fiscal.OperacaoFiscal.search],
        },
        {
          label: 'Configurar Tributação',
          icon: 'pi pi-angle-right',
          routerLink: [
            Rotas.Fiscal.base + Rotas.Fiscal.ConfigurarTributacao.search,
          ],
        },
        {
          label: 'IBPT',
          icon: 'pi pi-angle-right',
          routerLink: [Rotas.Fiscal.base + Rotas.Fiscal.Ibpt.search],
        },
      ],
    },
    // {
    //   label: 'Apuração',
    //   icon: 'pi pi-search',
    //   items: [
    //     {
    //       label: 'ICMS',
    //       icon: 'pi pi-angle-right',
    //       routerLink: ['/fiscal/apuracao/icms'],
    //     },
    //   ],
    // },
    {
      label: 'SPED',
      icon: 'pi pi-chart-pie',
      items: [
        {
          label: 'Fiscal',
          icon: 'pi pi-angle-right',
          routerLink: ['/fiscal/sped/efd-icms'],
        },
        {
          label: 'Contribuições',
          icon: 'pi pi-angle-right',
          routerLink: ['/fiscal/sped/efd-contribuicoes'],
        },
      ],
    },
  ],
};

const menuConfiguracao: MenuItem = {
  id: 'menu-configuracao',
  label: 'Configurações',
  icon: 'pi pi-wrench',
  items: [
    {
      label: 'Certificado',
      icon: 'pi pi-angle-right',
      routerLink: [Rotas.Configuracao.base + Rotas.Configuracao.Certificado],
    },
    {
      label: 'Conversão CFOP',
      icon: 'pi pi-angle-right',
      routerLink: [
        Rotas.Configuracao.base + Rotas.Configuracao.ConversaoCfop.search,
      ],
    },
    {
      label: 'Conversão CST',
      icon: 'pi pi-angle-right',
      routerLink: [
        Rotas.Configuracao.base + Rotas.Configuracao.ConversaoCst.search,
      ],
    },
    {
      label: 'MDF-e',
      icon: 'pi pi-angle-right',
      routerLink: [Rotas.Configuracao.base + Rotas.Configuracao.Mdfe],
    },
    {
      label: 'NF-e',
      icon: 'pi pi-angle-right',
      routerLink: [Rotas.Configuracao.base + Rotas.Configuracao.Nfe.search],
    },
    {
      label: 'OS',
      icon: 'pi pi-angle-right',
      routerLink: [Rotas.Configuracao.base + Rotas.Configuracao.Os],
    },
    {
      label: 'Modelo Doc. Fiscais',
      icon: 'pi pi-angle-right',
      routerLink: [
        Rotas.Configuracao.base +
          Rotas.Configuracao.ModeloDocumentoFiscal.search,
      ],
    },
    {
      label: 'Parâmetros',
      icon: 'pi pi-angle-right',
      routerLink: [Rotas.Configuracao.base + Rotas.Configuracao.Parametro],
    },
    {
      label: 'PDV',
      icon: 'pi pi-angle-right',
      routerLink: [Rotas.Configuracao.base + Rotas.Configuracao.Pdv.search],
    },
  ],
};

const menuRelatorios: MenuItem = {
  id: 'menu-relatorios',
  label: 'Relatorios',
  icon: 'pi pi-print',
  items: [
    {
      label: 'Cadastros',
      icon: 'pi pi-id-card',
      items: [
        {
          label: 'Clientes',
          icon: 'pi pi-angle-right',
          routerLink: [
            Rotas.Relatorios.base + Rotas.Relatorios.Cadastros.Clientes,
          ],
        },
        {
          label: 'Fornecedores',
          icon: 'pi pi-angle-right',
          routerLink: [
            Rotas.Relatorios.base + Rotas.Relatorios.Cadastros.Fornecedores,
          ],
        },
        {
          label: 'Produtos',
          icon: 'pi pi-angle-right',
          routerLink: [
            Rotas.Relatorios.base + Rotas.Relatorios.Cadastros.Produto,
          ],
        },
      ],
    },
    {
      label: 'Estoque',
      icon: 'pi pi-truck',
      items: [
        {
          label: 'Entradas',
          icon: 'pi pi-angle-right',
          routerLink: [
            Rotas.Relatorios.base + Rotas.Relatorios.Estoque.Entradas,
          ],
        },
        {
          label: 'Itens com estoque baixo',
          icon: 'pi pi-angle-right',
          routerLink: [Rotas.Relatorios.base + Rotas.Relatorios.Estoque.Itens],
        },
        {
          label: 'Inventário',
          icon: 'pi pi-angle-right',
          routerLink: [
            Rotas.Relatorios.base + Rotas.Relatorios.Estoque.Inventario,
          ],
        },
        {
          label: 'Movimento Produto',
          icon: 'pi pi-angle-right',
          routerLink: [
            Rotas.Relatorios.base + Rotas.Relatorios.Estoque.Movimento,
          ],
        },
      ],
    },

    {
      label: 'Financeiro',
      icon: 'pi pi-dollar',
      items: [
        {
          label: 'Contas a pagar',
          icon: 'pi pi-angle-right',
          routerLink: [
            Rotas.Relatorios.base + Rotas.Relatorios.Financeiro.Apagar,
          ],
        },
        {
          label: 'Contas a receber',
          icon: 'pi pi-angle-right',
          routerLink: [
            Rotas.Relatorios.base + Rotas.Relatorios.Financeiro.Areceber,
          ],
        },
        {
          label: 'Parcelas quitadas',
          icon: 'pi pi-angle-right',
          routerLink: [
            Rotas.Relatorios.base + Rotas.Relatorios.Financeiro.ParcelaQuitada,
          ],
        },
        {
          label: 'Ficha Financeira',
          icon: 'pi pi-angle-right',
          routerLink: [
            Rotas.Relatorios.base + Rotas.Relatorios.Financeiro.Ficha,
          ],
        },
      ],
    },

    {
      label: 'Movimento Caixa',
      icon: 'pi pi-briefcase',
      items: [
        {
          label: 'Lançamentos',
          icon: 'pi pi-angle-right',
          routerLink: [
            Rotas.Relatorios.base + Rotas.Relatorios.Movimento.Lancamento,
          ],
        },
      ],
    },

    {
      label: 'NF',
      icon: 'pi pi-file-export',
      items: [
        {
          label: 'Download XML',
          icon: 'pi pi-angle-right',
          routerLink: [Rotas.Relatorios.base + Rotas.Relatorios.NF.downloadXml],
        },
        {
          label: 'Relação de NF-e',
          icon: 'pi pi-angle-right',
          routerLink: [Rotas.Relatorios.base + Rotas.Relatorios.NF.relacaoNfe],
        },
        {
          label: 'Relação de NFS-e',
          icon: 'pi pi-angle-right',
          routerLink: [Rotas.Relatorios.base + Rotas.Relatorios.NF.relacaoNfse],
        },
      ],
    },

    {
      label: 'OS',
      icon: 'pi pi-file-edit',
      items: [
        {
          label: 'Relação',
          icon: 'pi pi-angle-right',
          routerLink: [Rotas.Relatorios.base + Rotas.Relatorios.Os.Relacao],
        },
      ],
    },

    {
      label: 'Vendas',
      icon: 'pi pi-shopping-bag',
      items: [
        {
          label: 'Comissões',
          icon: 'pi pi-angle-right',
          routerLink: [
            Rotas.Relatorios.base + Rotas.Relatorios.Vendas.Comissões,
          ],
        },

        {
          label: 'Clientes + Compram',
          icon: 'pi pi-angle-right',
          routerLink: [
            Rotas.Relatorios.base + Rotas.Relatorios.Vendas.Clientes,
          ],
        },

        {
          label: 'Lucratividade',
          icon: 'pi pi-angle-right',
          routerLink: [
            Rotas.Relatorios.base + Rotas.Relatorios.Vendas.Lucratividade,
          ],
        },

        {
          label: 'Prods. X Forma pag.',
          icon: 'pi pi-angle-right',
          routerLink: [Rotas.Relatorios.base + Rotas.Relatorios.Vendas.Forma],
        },

        {
          label: 'Produtos Vendidos',
          icon: 'pi pi-angle-right',
          routerLink: [
            Rotas.Relatorios.base + Rotas.Relatorios.Vendas.Produtos,
          ],
        },

        {
          label: 'Ranck de Produtos',
          icon: 'pi pi-angle-right',
          routerLink: [Rotas.Relatorios.base + Rotas.Relatorios.Vendas.Ranck],
        },

        {
          label: 'Vendas',
          icon: 'pi pi-angle-right',
          routerLink: [Rotas.Relatorios.base + Rotas.Relatorios.Vendas.Vendas],
        },
      ],
    },
  ],
};

export function criarMenu(roles: string[]): MenuItem[] {
  const menu: MenuItem[] = [
    { label: 'Dashboard', icon: 'pi pi-chart-line', routerLink: ['/'] },
    menuCadastro,
  ];

  if (isAdministrador(roles)) {
    menu.push(menuGerencial);
  }

  if (isFinanceiro(roles)) {
    menu.push(menuFinaceiro);
  }

  if (isComercial(roles)) {
    menu.push(menuComercial);
  }

  if (isSuprimentos(roles)) {
    menu.push(menuSuprimentos);
  }

  if (isFiscal(roles)) {
    menu.push(menuFiscal);
  }

  if (isUserConfiguracao(roles)) {
    menu.push(menuConfiguracao);
  }

  menu.push(menuRelatorios);

  return menu;
}

function isAdministrador(roles: string[]) {
  return roles.some((p) => 'ROLE_ADMIN'.includes(p));
}

function isComercial(roles: string[]) {
  return (
    isUserNfe(roles) ||
    isUserNfce(roles) ||
    isUserCte(roles) ||
    isUserNFSe(roles) ||
    isUserComissoes(roles) ||
    isUserOs(roles)
  );
}

function isUserVendas(roles: string[]) {
  return roles.some((p) => 'ROLE_MODULO_VENDA'.includes(p));
}

function isUserNfe(roles: string[]) {
  return roles.some((p) => 'ROLE_MODULO_NFE'.includes(p));
}

function isUserNfce(roles: string[]) {
  return roles.some((p) => 'ROLE_MODULO_NFCE'.includes(p));
}

function isUserMdfe(roles: string[]) {
  return roles.some((p) => 'ROLE_MODULO_MDFE'.includes(p));
}

function isUserCte(roles: string[]) {
  return roles.some((p) => 'ROLE_MODULO_CTE'.includes(p));
}

function isUserNFSe(roles: string[]) {
  return roles.some((p) => 'ROLE_MODULO_NFSE'.includes(p));
}

function isUserComissoes(roles: string[]) {
  return roles.some((p) => 'ROLE_MODULO_COMISSOES'.includes(p));
}

function isUserOs(roles: string[]) {
  return roles.some((p) => 'ROLE_MODULO_OS'.includes(p));
}

function isFinanceiro(roles: string[]) {
  return roles.some((p) => 'ROLE_MODULO_FINANCEIRO'.includes(p));
}

function isSuprimentos(roles: string[]) {
  return isUserEstoque(roles) || isUserCompras(roles);
}

function isUserCompras(roles: string[]) {
  return roles.some((p) => 'ROLE_MODULO_COMPRAS'.includes(p));
}

function isUserEstoque(roles: string[]) {
  return roles.some((p) => 'ROLE_MODULO_ESTOQUE'.includes(p));
}

function isFiscal(roles: string[]) {
  return (
    isUserSpedFiscal(roles) ||
    isUserSpedContribuicoes(roles) ||
    isUserTributacao(roles)
  );
}

function isUserSpedFiscal(roles: string[]) {
  return roles.some((p) => 'ROLE_MODULO_SPED_FISCAL'.includes(p));
}

function isUserSpedContribuicoes(roles: string[]) {
  return roles.some((p) => 'ROLE_MODULO_SPED_CONTRIBUICOES'.includes(p));
}

function isUserTributacao(roles: string[]) {
  return roles.some((p) => 'ROLE_MODULO_TRIBUTACAO'.includes(p));
}

function isUserConfiguracao(roles: string[]) {
  return true;
}
