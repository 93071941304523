import { Component, OnInit } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { RegistroService } from 'src/app/view/novo-cadastro/services/registro.service';
import { Plano } from '../../model/dashboard';
import { DashboardCheckoutAssinaturaComponent } from '../dashboard-checkout-assinatura/dashboard-checkout-assinatura.component';
import { DashboardPlanoComponent } from '../dashboard-plano/dashboard-plano.component';

@Component({
  selector: 'app-dashboard-planos',
  standalone: true,
  imports: [
    SharedModule,
    DashboardPlanoComponent,
    DashboardCheckoutAssinaturaComponent,
  ],
  templateUrl: './dashboard-planos.component.html',
  styleUrl: './dashboard-planos.component.scss',
})
export class DashboardPlanosComponent implements OnInit {
  display = false;

  planos: Plano[] = [];
  planosSelecionados: Plano[] = [];
  idplan = -1;

  activeButtonYearPlan = false;
  activeButtonMotherPlan = true;
  activeButtonSemesterPlan = false;

  constructor(private service: RegistroService) {}

  ngOnInit(): void {
    const planos = [
      {
        id: 4,
        nome: 'Bronze',
        descricao: 'Ideal para quem quer somente emitir documentos fiscais',
        valor: 39.9,
        tipo: 'M',
        modulos: [
          {
            nome: 'Emissão de documentos eletronicos(NFe,NFCe,MDFe)',
            descricao: 'Emissão de MDF-e',
          },
        ],
      },
      {
        id: 5,
        nome: 'Prata',
        descricao:
          'Ideal para empresas que buscam um sistema de gestão completo',
        valor: 59.9,
        tipo: 'M',
        modulos: [
          {
            nome: 'Gestão comercial completa',
            descricao: 'Gestão comercial completa',
          },
        ],
      },
      {
        id: 5,
        nome: 'Ouro',
        descricao:
          'Ideal para empresas que necessitam emitir notas fiscais e buscam um sistema de gestão completo',
        valor: 89.9,
        tipo: 'M',
        modulos: [
          {
            nome: 'Gestão comercial completa',
            descricao: 'Gestão comercial completa',
          },
          {
            nome: 'Emissão de documentos eletronicos(NFe,NFCe,MDFe)',
            descricao: 'Emissão de MDF-e',
          },
        ],
      },
      {
        id: 6,
        nome: 'Bronze',
        descricao: 'Ideal para quem quer somente emitir documentos fiscais',
        valor: 31.9,
        desconto: 8,
        tipo: 'S',
        modulos: [
          {
            nome: 'Emissão de documentos eletronicos(NFe,NFCe,MDFe)',
            descricao: 'Emissão de MDF-e',
          },
        ],
      },
      {
        id: 7,
        nome: 'Prata',
        descricao:
          'Ideal para empresas que buscam um sistema de gestão completo',
        valor: 39.9,
        desconto: 20,
        tipo: 'S',
        modulos: [
          {
            nome: 'Gestão comercial completa',
            descricao: 'Gestão comercial completa',
          },
        ],
      },
      {
        id: 8,
        nome: 'Ouro',
        descricao:
          'Ideal para empresas que necessitam emitir notas fiscais e buscam um sistema de gestão completo',
        valor: 69.9,
        desconto: 20,
        tipo: 'S',
        modulos: [
          {
            nome: 'Gestão comercial completa',
            descricao: 'Gestão comercial completa',
          },
          {
            nome: 'Emissão de documentos eletronicos(NFe,NFCe,MDFe)',
            descricao: 'Emissão de MDF-e',
          },
        ],
      },
      {
        id: 9,
        nome: 'Bronze',
        descricao: 'Ideal para quem quer somente emitir documentos fiscais',
        valor: 23.9,
        desconto: 16,
        tipo: 'A',
        modulos: [
          {
            nome: 'Emissão de documentos eletronicos(NFe,NFCe,MDFe)',
            descricao: 'Emissão de MDF-e',
          },
        ],
      },
      {
        id: 10,
        nome: 'Prata',
        descricao:
          'Ideal para empresas que buscam um sistema de gestão completo',
        valor: 35.9,
        desconto: 24,
        tipo: 'A',
        modulos: [
          {
            nome: 'Gestão comercial completa',
            descricao: 'Gestão comercial completa',
          },
        ],
      },
      {
        id: 11,
        nome: 'Ouro',
        descricao:
          'Ideal para empresas que necessitam emitir notas fiscais e buscam um sistema de gestão completo',
        valor: 53.9,
        desconto: 36,
        tipo: 'A',
        modulos: [
          {
            nome: 'Gestão comercial completa',
            descricao: 'Gestão comercial completa',
          },
          {
            nome: 'Emissão de documentos eletronicos(NFe,NFCe,MDFe)',
            descricao: 'Emissão de MDF-e',
          },
        ],
      },
    ];
    //   this.planos = planos;
    this.onSelectYearPlan();
    this.service.getPlans().subscribe({
      next: (response) => {
        this.planos = response;
        this.onSelectYearPlan();
      },
    });
  }

  onSelectYearPlan() {
    this.activeButtonYearPlan = true;
    this.activeButtonMotherPlan = false;
    this.activeButtonSemesterPlan = false;
    this.planosSelecionados = this.planos.filter((p) => p.tipo === 'A');
  }

  onSelectMotherPlan() {
    this.activeButtonYearPlan = false;
    this.activeButtonMotherPlan = true;
    this.activeButtonSemesterPlan = false;
    this.planosSelecionados = this.planos.filter((p) => p.tipo === 'M');
  }

  onSelectSemesterPlan() {
    this.activeButtonYearPlan = false;
    this.activeButtonMotherPlan = false;
    this.activeButtonSemesterPlan = true;
    this.planosSelecionados = this.planos.filter((p) => p.tipo === 'S');
  }

  onPurchasePlan(plan: Plano) {
    this.idplan = plan.id;
    this.display = true;
  }
}
