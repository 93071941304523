import { Injectable } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';
import { AuthService } from '../../seguranca/auth.service';
import { EmpresaResponse } from '../../view/cadastros/administrativos/empresa-page/models/empresa';
import { EmpresaService } from './empresa.service';

@Injectable({
  providedIn: 'root',
})
export class GlobalDataService {
  private empresa$ = new BehaviorSubject<EmpresaResponse | undefined>(
    undefined
  );

  constructor(
    private empresaService: EmpresaService,
    private auth: AuthService
  ) {}

  clearEmpresa() {
    this.empresa$.next(undefined);
  }

  getEmpresa() {
    return this.empresa$.pipe(
      switchMap((empresa) => {
        if (empresa) {
          return of(empresa);
        } else {
          // Se não existe valor, busca na API e armazena o resultado
          return this.empresaService
            .getById(this.auth.getIdEmpresa())
            .pipe(tap((result) => this.empresa$.next(result)));
        }
      }),
      // Filtra para garantir que apenas valores definidos sejam emitidos
      filter((empresa): empresa is EmpresaResponse => empresa !== undefined)
    );
  }

  get empresa() {
    return this.empresa$.pipe(
      switchMap((empresa) => {
        if (empresa) {
          return of(empresa);
        } else {
          return this.getEmpresa();
        }
      })
    );
  }
}
