<div class="alerta" *ngIf="mensagem || mensagemBloqueio">
  <span *ngIf="mensagem">
    <strong> {{ mensagem }}</strong>
  </span>

  <span *ngIf="mensagemBloqueio" class="p-p-3">
    <strong> {{ mensagemBloqueio }}</strong>
  </span>

  <a
    id="btn-assinar"
    pButton
    label="Assine Agora"
    data-cy="btn-novo"
    routerLink="/assinatura"
    *ngIf="controlaAssinatura && mensagem"
  ></a>
</div>
