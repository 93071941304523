import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/seguranca/auth.service';
import { environment } from 'src/environments/environment';
import { NotificacaoVencimentoService } from '../service/notificacao-vencimento.service';

@Component({
  selector: 'app-layout-notificacao-vencimento',
  templateUrl: './layout-notificacao-vencimento.component.html',
  styleUrl: './layout-notificacao-vencimento.component.scss',
})
export class LayoutNotificacaoVencimentoComponent implements OnInit {
  controlaAssinatura = environment.controlaAssinatura;

  _mensagem?: string | null;

  mensagemBloqueio?: string;

  constructor(
    private notificacaoVencimentoService: NotificacaoVencimentoService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.notificacaoVencimentoService.mensagemVencimento.subscribe({
      next: (msg) => {
        this._mensagem = msg;
      },
    });

    if (!this._mensagem && this.authService.jwtPayload?.mensagemVencimento) {
      this.notificacaoVencimentoService.setMensagem(
        this.authService.jwtPayload?.mensagemVencimento
      );
    }

    this.mensagemBloqueio = this.authService.jwtPayload?.mensagemBloqueio;
  }

  get mensagem() {
    return this._mensagem;
  }
}
