import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from 'src/app/seguranca/auth.service';
import { environment } from 'src/environments/environment';

export const AssinaturaGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  const router = inject(Router);

  if (
    state.url === '/assinatura' &&
    environment.controlaAssinatura &&
    !authService.jwtPayload?.solicitarPagamento
  ) {
    router.navigate(['/dashboard']);

    return false;
  }

  return true;
};
