import {
  FormArray,
  FormControl,
  FormGroup,
  UntypedFormGroup,
} from '@angular/forms';

export function markFormGroupTouched(formGroup: UntypedFormGroup | FormGroup) {
  Object.keys(formGroup.controls).forEach((field) => {
    const control = formGroup.get(field);

    if (control instanceof FormControl) {
      control.markAllAsTouched();
    } else if (
      control instanceof FormGroup ||
      control instanceof UntypedFormGroup
    ) {
      markFormGroupTouched(control);
    } else if (control instanceof FormArray) {
      control.markAllAsTouched();
    }
  });
}
