import { Component, Input } from '@angular/core';
import { CardNumberPipe } from 'src/app/shared/pipes/card-number.pipe';

@Component({
  selector: 'app-credit-card-preview',
  standalone: true,
  imports: [CardNumberPipe],
  templateUrl: './credit-card-preview.component.html',
  styleUrl: './credit-card-preview.component.scss',
})
export class CreditCardPreviewComponent {
  @Input() cvc?: string = '';
  @Input() titular?: string = '';
  @Input() vencimento?: string = '';
  @Input() numeroCartao?: string = '';
  @Input() rotate = false;

  get formattedVencimento(): string {
    if (this.vencimento && this.vencimento.length === 6) {
      const year = this.vencimento.slice(0, 4);
      const month = this.vencimento.slice(4, 6);
      return `${year}/${month}`;
    }
    return this.vencimento || '';
  }
}
