<div class="container-form">
  <div class="banner">
    <img class="img" src="assets/images/kron/background.svg" />

    <div class="banner-text">
      <h1>ERP fácil de usar e sem burocracia</h1>
      <ul>
        <li class="text-list p-ai-center no">Sem fidelidade</li>
        <li class="text-list p-d-flex p-ai-center">Suporte gratuito</li>
        <li class="text-list p-d-flex p-ai-center">Atualizações périódicas</li>
        <li class="text-list p-d-flex p-ai-center">100% em nuvem</li>
      </ul>
    </div>

    <div class="banner-img">
      <img
        fetchpriority="high"
        src="assets/images/imagem-banner-cadastro.png"
        alt="Sistema KronERP - Experimente grátis"
        width="804"
        height="603"
      />
    </div>
  </div>
  <div class="form-creator">
    <h2 class="p-pb-4 text-24 text-md-28 p-pt-0 p-text-center">
      Experimente grátis por 15 dias!
    </h2>
    <div class="justify-content-center">
      <div class="p-md-12">
        <div class="box">
          <div class="box-form p-p-5">
            <form [formGroup]="form" class="p-fluid" (submit)="onSubmit()">
              <div class="form-group">
                <label class="label" for="name">Seu CNPJ</label>

                <p-inputMask
                  formControlName="cnpj"
                  placeholder="Seu CNPJ"
                  mask="99.999.999/9999-99"
                  [unmask]="true"
                  className="form-control"
                ></p-inputMask>
                <app-form-errors [control]="form.get('cnpj')"></app-form-errors>
              </div>
              <div class="form-group">
                <label class="label" for="name">Seu nome</label>
                <input
                  type="text"
                  pInputText
                  placeholder="Seu nome"
                  formControlName="nome"
                  class="form-control"
                />
                <app-form-errors [control]="form.get('nome')"></app-form-errors>
              </div>
              <div class="form-group">
                <label class="label" for="name">Seu Telefone</label>
                <p-inputMask
                  mask="(99) 9 9999-9999"
                  [unmask]="true"
                  placeholder="Seu Telefone"
                  formControlName="fone"
                ></p-inputMask>
                <app-form-errors [control]="form.get('fone')"></app-form-errors>
              </div>
              <div class="form-group">
                <label class="label" for="email">Seu e-mail</label>
                <input
                  type="email"
                  pInputText
                  placeholder="Seu e-mail"
                  formControlName="email"
                  class="form-control"
                />
                <app-form-errors
                  [control]="form.get('email')"
                ></app-form-errors>
              </div>
              <div class="form-group">
                <label class="label" for="name">Sua melhor senha</label>
                <input
                  type="password"
                  pInputText
                  placeholder="Sua melhor senha"
                  formControlName="senha"
                  class="form-control"
                />
                <app-form-errors
                  [control]="form.get('senha')"
                ></app-form-errors>
              </div>
              <div class="form-group p-text-center">
                <button
                  pButton
                  type="submit"
                  [disabled]="form.invalid"
                  *ngIf="!loadding"
                  label="Comece agora"
                ></button>
                <p-progressSpinner
                  strokeWidth="8"
                  [style]="{ width: '50px', height: '50px' }"
                  *ngIf="loadding"
                >
                </p-progressSpinner>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <p class="p-pt-4 p-pb-2 p-text-center">
      Já tem uma conta?
      <a [routerLink]="['/login']">Acessar o sistema</a>
    </p>
  </div>
</div>
