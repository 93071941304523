import { Component } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/seguranca/auth.service';
import {
  cnpjValidator,
  strongPasswordValidator,
} from 'src/app/shared/helper/validators';
import { GlobalDataService } from 'src/app/shared/services/global-data.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormErrorsComponent } from '../../../../shared/components/form/form-errors/form-errors.component';
import { RegistroRequest } from '../../models/registro';
import { RegistroService } from '../../services/registro.service';
import { NovoCadastroPublicidadeCheckComponent } from '../novo-cadastro-publicidade-check/novo-cadastro-publicidade-check.component';

@Component({
  selector: 'app-novo-cadastro-form',
  standalone: true,
  imports: [
    SharedModule,
    FormErrorsComponent,
    NovoCadastroPublicidadeCheckComponent,
  ],
  templateUrl: './novo-cadastro-form.component.html',
  styleUrl: './novo-cadastro-form.component.scss',
})
export class NovoCadastroFormComponent {
  loadding = false;

  form = this.createForm();

  constructor(
    private service: RegistroService,
    private auth: AuthService,
    private globalService: GlobalDataService,
    private formBuilder: FormBuilder,
    private router: Router
  ) {}

  onSubmit() {
    if (this.form.invalid) return;

    this.loadding = true;
    var request = this.form.value as RegistroRequest;

    this.service
      .create(request)
      .subscribe({
        next: async () => {
          this.login(request.email, request.senha);
        },
      })
      .add(() => (this.loadding = false));
  }

  private login(login: string, senha: string) {
    this.auth
      .login(login, senha)
      .then(() => {
        this.globalService.clearEmpresa();
        const redirect: string = this.auth.redirectUrl || '/dashboard';
        this.router.navigate([redirect]);

        this.loadding = false;
      })
      .catch(() => {
        this.loadding = false;
        this.router.navigate(['/login']);
      });
  }

  private createForm() {
    return this.formBuilder.group({
      nome: new FormControl<string>('', {
        validators: [Validators.required, Validators.minLength(8)],
        nonNullable: true,
      }),
      email: new FormControl<string>('', {
        validators: [Validators.required, Validators.email],
        nonNullable: true,
      }),
      senha: new FormControl<string>('', {
        validators: [Validators.required, strongPasswordValidator()],
        nonNullable: true,
      }),
      cnpj: new FormControl<string>('', {
        validators: [Validators.required, cnpjValidator()],
        nonNullable: true,
      }),
      fone: new FormControl<string>('', {
        validators: [Validators.required],
        nonNullable: true,
      }),
    });
  }
}
