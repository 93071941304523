import {
  AfterContentInit,
  Component,
  ContentChild,
  Input,
} from '@angular/core';
import { FormControlName, NgModel } from '@angular/forms';

@Component({
  selector: 'app-container-input',
  templateUrl: './container-input.component.html',
  styleUrls: ['./container-input.component.scss'],
})
export class ContainerInputComponent implements AfterContentInit {
  @Input() label?: string;
  @Input() msg?: string;
  @Input() info?: string;
  @Input() classe?: string = 'control-label';
  @Input() requerido = false;

  @ContentChild(NgModel, { static: true }) model?: NgModel;
  @ContentChild(FormControlName, { static: true }) control?: FormControlName;

  input?: NgModel | FormControlName;

  constructor() {}

  ngAfterContentInit() {
    this.input = this.model || this.control;

    if (this.input === undefined) {
      throw new Error(
        'Esse componente precisa ser usado com uma diretiva ngModel ou formControlName'
      );
    }
  }

  hasSuccess(): boolean {
    return (
      Boolean(this.input?.valid) &&
      Boolean(this.input?.dirty || this.input?.touched)
    );
  }

  hasError(): boolean {
    return Boolean(
      this.input?.invalid && (this.input?.dirty || this.input?.touched)
    );
  }
}
