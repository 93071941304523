import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { getErrorMsg, TypeValidation } from 'src/app/shared/helper/validators';

@Component({
  selector: 'app-form-errors',
  standalone: true,
  imports: [],
  templateUrl: './form-errors.component.html',
  styleUrl: './form-errors.component.scss',
})
export class FormErrorsComponent {
  @Input() control: AbstractControl | null = null;

  hasError(): boolean {
    const invalid =
      Boolean(this.control) &&
      Boolean(this.control?.invalid) &&
      (Boolean(this.control?.dirty) || Boolean(this.control?.touched));

    return invalid;
  }

  buscarErroForm(): string[] {
    const errors = this.control?.errors;

    if (!errors) return [];

    let messages: string[] = [];

    Object.keys(errors).forEach((errorKey) => {
      const errorValue = errors[errorKey];
      const message = getErrorMsg('', errorKey as TypeValidation, errorValue);
      if (Array.isArray(message)) {
        messages = [...message, ...messages];
      } else {
        messages.push(message);
      }
    });

    return messages;
  }
}
