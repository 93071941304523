import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CardEncripted, EncryptRequest } from '../model/dashboard';

declare var PagSeguro: any;

@Injectable({
  providedIn: 'root',
})
export class PagSeguroService {
  constructor() {
    this.loadJavascriptPagseguro();
  }

  doEncrypt(request: Partial<EncryptRequest>): CardEncripted {
    const cardInfo = {
      publicKey: environment.pagSeguroPublicKey,
      holder: request.holder,
      number: request.number,
      expMonth: request.expMonth,
      expYear: request.expYear,
      securityCode: request.securityCode,
    };

    const card = PagSeguro.encryptCard(cardInfo);

    return card;
  }

  private loadJavascriptPagseguro() {
    new Promise<void>((resolve) => {
      let script: HTMLScriptElement = document.createElement('script');
      script.addEventListener('load', (r) => resolve());
      script.src =
        'https://assets.pagseguro.com.br/checkout-sdk-js/rc/dist/browser/pagseguro.min.js';
      document.head.appendChild(script);
    });
  }
}
