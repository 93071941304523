import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotificacaoVencimentoService {
  private mensagemVencimento$ = new BehaviorSubject<string | null | undefined>(
    null
  );

  constructor() {}

  setMensagem(mensagem?: string) {
    this.mensagemVencimento$.next(mensagem);
  }

  get mensagemVencimento(): Observable<string | null | undefined> {
    return this.mensagemVencimento$.asObservable();
  }
}
