<div [class.has-error]="hasError()" [attr.data-cy]="'container-' + label">
  <label
    [class]="classe"
    [class.required]="requerido"
    [attr.data-cy]="'label-' + label"
    >{{ label }}</label
  >
  <i
    class="pi pi-info-circle"
    style="margin-left: 5px"
    [title]="info"
    *ngIf="info"
    [attr.data-cy]="'info-icon-' + label"
  ></i>
  <ng-content></ng-content>
  <app-error
    [label]="label"
    [control]="input"
    [attr.data-cy]="'error-' + label"
  ></app-error>
</div>
